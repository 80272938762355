/* .cards {
    padding-left: 5rem;
    padding-right: 5rem;
    padding-top: 5rem;
    background: #fff;
  } */

  .elite {
    padding-top: 0;
    padding-bottom: 5rem;
  }

  .home-h1 {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    font-family: "Playfair Display", serif;
  }
  
  .cards__container {
    font-family: "Playfair Display", serif;
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 1120px;
    width: 90%;
    margin: 0 auto;
  }
  
  .cards__wrapper {
    position: relative;
    font-family: "Playfair Display", serif;
    margin: 50px 0 45px;
  }
  
  .cards__items {
    font-family: "Playfair Display", serif;
    margin-bottom: 24px;
  }
  
  .cards__item {
    font-family: "Playfair Display", serif;
    display: flex;
    flex: 1;
    margin: 0 1rem;
    border-radius: 10px;
  }
  
  .cards__item__link {
    font-family: "Playfair Display", serif;
    display: flex;
    flex-flow: column;
    width: 100%;
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
  }
  
  .cards__item__pic-wrap {
    font-family: "Playfair Display", serif;
    position: relative;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;
  }
  
  .fade-img {
    font-family: "Playfair Display", serif;
    animation-name: fade-img;
    animation-duration: 2s;
  }
  
  .cards__item__pic-wrap::after {
    font-family: "Playfair Display", serif;
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 10px;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 24px;
    font-weight: 700;
    color: #fff;
    background-color: #0F8A5F;
    box-sizing: border-box;
  }
  
  .cards__item__img {
    font-family: "Playfair Display", serif;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
  }
  
  .cards__item__img:hover {
    font-family: "Playfair Display", serif;
    transform: scale(1.1);
  }
  
  .cards__item__info {
    padding: 20px 30px 30px;
  }
  
  .cards__item__text {
    font-family: "Playfair Display", serif;
    font-weight: 650;
    color: #252e48;
    font-size: 20px;
    line-height: 24px;
  }
  
  @media only screen and (min-width: 1200px) {
    .content__blog__container {
      width: 84%;
    }
  }
  
  @media only screen and (min-width: 1024px) {
    .cards__items {
      display: flex;
    }
  }
  
  @media only screen and (max-width: 1024px) {
    .cards__item {
      margin-bottom: 2rem;
    }
  }

  @media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: portrait) { 
    .home-h1  {
      font-size: 1.75rem;
    }

    .span {
      font-size: 1.6rem;
    }

    .elite {
      padding: 0;
    }
}