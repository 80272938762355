html,body{
  overflow-x: hidden;
  height: 100vh;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'PT Sans', sans-serif;
  }
  

.home {
    display: flex;
    height: 90vh;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
}



@media screen and (max-width: 768px) {
  .recap {
    /* transform: scale(0.77); */
    justify-content: start !important;
  }
  .contact-title {
    font-size: 1.5rem;
    justify-content: center;
  }
}

@media only screen 
and (min-device-width: 375px) 
and (max-device-width: 812px) 
and (-webkit-min-device-pixel-ratio: 3)
and (orientation: portrait) { 
  .form {
    
  }

}